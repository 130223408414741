import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { ResourcesInsightsOfficer, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'resources';

export default function() {
  const { contentfulResourcesPage, allContentfulPost } = useStaticQuery(graphql`
    {
      contentfulResourcesPage(slug: { eq: "resources" }) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroCtaLink {
          slug
          internal {
            type
          }
        }
        heroCtaText
        heroCtaCopy
        heroDescription {
          json
        }
        heroHeadline
        heroImage {
          ...ImageSharpLarge
        }
        categories {
          id
          name
          expandCta
        }
        spacerNavigator {
          headline
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
      }
      allContentfulPost(
        sort: { order: [ASC, DESC], fields: [pinned, createdAt] },
        filter: {slug: {ne: "subscribe-investor-survey"}, microsite: {ne: "is"}}
      ) 
      {
        group(field: category___id) {
          fieldValue
          totalCount
          nodes {
            pinned
            createdAt
            headline
            ctaText
            slug
            redirectUrl
            internal {
              type
            }
            image {
              ...ImageSharpStoryCard
            }
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulResourcesPage.metaTitle,
      description: contentfulResourcesPage.metaDescription,
      image:
        contentfulResourcesPage.image &&
        contentfulResourcesPage.image.localFile.childImageSharp.fluid.src,
    },
    hero: {
      headline: contentfulResourcesPage.heroHeadline,
      body: contentfulResourcesPage.heroDescription,
      buttonHeadline: contentfulResourcesPage.heroCtaCopy,
      buttonText: contentfulResourcesPage.heroCtaText,
      buttonUrl:
        contentfulResourcesPage.heroCtaLink &&
        linkResolver({
          type: contentfulResourcesPage.heroCtaLink[0].internal.type,
          slug: contentfulResourcesPage.heroCtaLink[0].slug,
          postPrefix,
        }),
      image: contentfulResourcesPage.heroImage,
      background: 'dark-1',
    },
    categories: contentfulResourcesPage.categories.map(({ id, name, expandCta }) => ({
      headline: contentfulResourcesPage.categories.length > 1 && name,
      buttonText: expandCta,
      cards: allContentfulPost.group
        .filter(({ fieldValue }) => fieldValue === id)
        .pop()
        .nodes.map((card) => ({
          headline: card.headline,
          linkText: card.ctaText,
          linkUrl: linkResolver({
            type: card.internal.type,
            slug: card.redirectUrl || card.slug,
            postPrefix,
          }),
          image: card.image,
        })),
    })),
    cta: {
      headline: contentfulResourcesPage.spacerNavigator[0].headline,
      buttonText: contentfulResourcesPage.spacerNavigator[0].ctaText,
      buttonType:
        contentfulResourcesPage.spacerNavigator[0].ctaLink &&
        contentfulResourcesPage.spacerNavigator[0].ctaLink[0].internal.type,
      buttonUrl:
        contentfulResourcesPage.spacerNavigator[0].ctaLink &&
        linkResolver({
          type: contentfulResourcesPage.spacerNavigator[0].ctaLink[0].internal.type,
          slug:
            contentfulResourcesPage.spacerNavigator[0].ctaLink[0].redirectUrl ||
            contentfulResourcesPage.spacerNavigator[0].ctaLink[0].slug,
          postPrefix,
        }),
      primary: true,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <ResourcesInsightsOfficer doc={doc} Link={Link} />
    </Layout>
  );
}
